<template>
  <div class="flex flex-col h-screen">
    <header class="text-white py-8 font-bold text-lg" :class="backgroundColor">
      <div class="container mx-auto max-w-lg pt-1">
        <a href="/">
          <img :src="logo" alt="Logo" class="h-8">
          {{ name }}

          <div class="italic text-sm">{{ subtitle }}</div>
        </a>
      </div>
    </header>

    <main class="container mx-auto mb-auto max-w-lg">
      <div v-if="status === 'submit_error'" class="bg-red-500 text-white px-5 py-4 mt-6 mb-5 rounded">
        <i class="fa-solid fa-exclamation-circle pr-1.5"></i>
        <span v-if="message">{{ message }}</span>
        <span v-else><strong>Oh no!</strong> Something went wrong, but we've been notified</span>
      </div>

      <DemoBookingForm @changeStatus="changeStatus" v-if="status !== 'submit_ok'" :status="status" />

      <div class="mt-5 mb-5" v-if="status === 'submit_ok'">
        <h1 class="text-4xl mt-5 mb-7">Success!</h1>
        <p class="text-lg mb-3">
          {{ successTitle }}
        </p>

        <p class="text-gray-600">
          {{ successSubtitle }}
        </p>
      </div>
    </main>

    <footer class="w-full bg-gray-700 text-white py-4 static bottom-0 text-center text-sm">
      <div class="container mx-auto max-w-lg">
        Powered by
        <a href="https://www.crowdengage.com/" target="_blank" class="font-bold">crowdEngage</a>
      </div>
    </footer>
  </div>
</template>

<script>
import DemoBookingForm from "./components/DemoBookingForm.vue"

export default {
  name: "App",
  components: {
    DemoBookingForm
  },
  data() {
    return {
      // statuses: unsubmitted, submit_ok, submit_error
      status: "unsubmitted",
      message: null
    }
  },
  methods: {
    changeStatus(status, message) {
      this.status = status
      this.message = message
    }
  },
  computed: {
    name() {
      return process.env.VUE_APP_NAME
    },
    subtitle() {
      return process.env.VUE_APP_SUBTITLE
    },
    logo() {
      return process.env.VUE_APP_LOGO_URL
    },
    backgroundColor() {
      return `bg-${process.env.VUE_APP_COLOR}`
    },
    successTitle() {
      return process.env.VUE_APP_SUCCESS_TITLE
    },
    successSubtitle() {
      return process.env.VUE_APP_SUCCESS_SUBTITLE
    }
  }
}
</script>
