<template>
  <div class="my-5">
    <p class="mb-6">
      {{ helpText }}
    </p>

    <form @submit.prevent="submit">
      <div class="mb-8">
        <label for="first_name" class="block mb-1 font-bold text-gray-600">First Name<sup><abbr title="Required" class="text-red-500">*</abbr></sup></label>
        <input v-model="formData.first_name" :disabled="busy" @keyup="fieldChange" @blur="fieldChange" type="text" id="first_name" placeholder="First Name" autofocus class="border border-gray-300 shadow p-3 w-full rounded" :class="outlineColor">
      </div>

      <div class="mb-8">
        <label for="last_name" class="block mb-1 font-bold text-gray-600">Last Name<sup><abbr title="Required" class="text-red-500">*</abbr></sup></label>
        <input v-model="formData.last_name" :disabled="busy" @keyup="fieldChange" @blur="fieldChange" type="text" id="last_name" placeholder="Last Name" class="border border-gray-300 shadow p-3 w-full rounded" :class="outlineColor">
      </div>

      <div class="mb-8">
        <label for="organisation" class="block mb-1 font-bold text-gray-600">Venue<sup><abbr title="Required" class="text-red-500">*</abbr></sup></label>
        <input v-model="formData.organisation" :disabled="busy" @keyup="fieldChange" @blur="fieldChange" type="text" id="organisation" placeholder="Venue" class="border border-gray-300 shadow p-3 w-full rounded" :class="outlineColor">
      </div>

      <div class="mb-8">
        <label for="email" class="block mb-1 font-bold text-gray-600">Email Address<sup><abbr title="Required" class="text-red-500">*</abbr></sup></label>
        <input v-model="formData.email" :disabled="busy" @keyup="fieldChange" @blur="fieldChange" type="email" id="email" placeholder="Email Address" class="border border-gray-300 shadow p-3 w-full rounded" :class="outlineColor">
      </div>

      <div class="mb-8">
        <label for="mobile" class="block mb-1 font-bold text-gray-600">Mobile Number<sup><abbr title="Required" class="text-red-500">*</abbr></sup></label>
        <input v-model="formData.mobile" :disabled="busy" @keyup="fieldChange" @blur="fieldChange" type="tel" id="mobile" placeholder="Mobile Number" class="border border-gray-300 shadow p-3 w-full rounded" :class="outlineColor">
        <div v-if="status === 'submit_error'" class="text-gray-500 text-sm pt-2">If your mobile number is not accepted, please try international format, beginning with, e.g. +1, +44…</div>
      </div>

      <button class="block w-full outline outline-1 shadow-md text-white font-bold p-4 rounded-lg disabled:bg-white disabled:text-gray-300" :class="backgroundColor" :disabled="busy || !valid">
        <i class="fa-solid fa-paper-plane"></i>&nbsp;{{ buttonText }}
      </button>

      <div class="mt-4 text-sm text-gray-600 w-full text-center" v-if="!valid">
        (all fields are required)
      </div>
    </form>
  </div>
</template>

<script>
import * as yup from "yup"

const schema = yup.object().shape({
  first_name: yup.string().trim().required(),
  last_name: yup.string().trim().required(),
  organisation: yup.string().trim().required(),
  mobile: yup.string().trim().required().matches(/^\+?[0-9()\- ]{7,}$/),
  email: yup.string().email(),
})

export default {
  props: ["status"],
  data() {
    return {
      busy: false,
      valid: false,
      formData: {
        first_name: "",
        last_name: "",
        organisation: "",
        mobile: "",
        email: "",
      }
    }
  },

  methods: {
    async submit() {
      this.busy = true

      fetch("https://app.crowdengage.com/demo/", {
        method: "POST",
        body: JSON.stringify(this.dataToSubmit)
      }).then((response) => {
        if (response.status === 200) {
          return response.json()
        } else {
          this.busy = false
          this.$emit("changeStatus", "submit_error")
          return null
        }
      }).then((json) => {
        if (json.ok) {
          this.$emit("changeStatus", "submit_ok")
        } else {
          this.busy = false
          this.$emit("changeStatus", "submit_error", json.message)
        }
      })
    },

    fieldChange() {
      this.checkForm().then((valid) => {
        this.valid = valid
      })
    },

    checkForm() {
      return schema.isValid(this.formData)
    }
  },
  computed: {
    helpText() {
      return process.env.VUE_APP_HELP_TEXT
    },
    backgroundColor() {
      return `bg-${process.env.VUE_APP_COLOR}`
    },
    outlineColor() {
      return `focus:outline-${process.env.VUE_APP_COLOR}`
    },
    customFields() {
      try {
        return JSON.parse(process.env.VUE_APP_CUSTOM_FIELDS)
      } catch (error) {
        return {}
      }
    },
    buttonText() {
      return process.env.VUE_APP_BUTTON_TEXT
    },
    dataToSubmit() {
      return {
        ...this.formData,
        ...this.customFields,
      }
    }
  }
}
</script>
